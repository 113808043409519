@use "../../utils/variables" as vars;
@import "../../styles/fonts.scss";

.container {
  justify-content: space-between;
  color: vars.$black;
  margin-top: 160px;
  & h3 {
    @extend .H3;
  }
  @media screen and (max-width: vars.$big) {
    margin-top: calc(160px / vars.$big * 100vw);
  }

  @media screen and (max-width: vars.$medium) {
    margin-top: calc(120px / vars.$medium * 100vw);
  }

  @media screen and (max-width: vars.$small) {
    margin-top: calc(80px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.map-container {
  grid-column: 1 / -1;
  width: 100%;
  margin-top: 120px;
  & img {
    width: 100%;
  }
  @media screen and (max-width: vars.$big) {
    margin-top: calc(120px / vars.$big * 100vw);
  }

  @media screen and (max-width: vars.$medium) {
    margin-top: calc(80px / vars.$medium * 100vw);
  }

  @media screen and (max-width: vars.$small) {
    display: none;
  }
}

.description {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  grid-column: 4 / 13;
  margin-bottom: 60px;
  @media screen and (max-width: vars.$big) {
    margin-bottom: calc(60px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    grid-column: 2 / 5;
    margin-bottom: calc(50px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    grid-column: -1 / 1;
    margin-bottom: calc(50px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.countries-list {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  text-align: justify;
  overflow: hidden;
  height: 76px;
  grid-column: 4 / 13;
  text-transform: uppercase;
  @media screen and (max-width: vars.$big) {
    height: calc(76px / vars.$big * 100vw);
    column-gap: calc(10px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    grid-column: 2 / 5;
    height: calc(59px / vars.$medium * 100vw);
    column-gap: calc(10px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    height: calc(92px / vars.$small * vars.$smallCoef * 100vw);
    grid-column: 1 / -1;
    column-gap: calc(10px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.country-link {
  position: relative;
  margin-right: 34px;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    right: -31px;
    top: 48%;
    transform: translateY(-50%);
    border-radius: 50%;
    width: 17px;
    height: 17px;
    background: currentColor;
    @media screen and (max-width: vars.$big) {
      right: calc(-31px / vars.$big * 100vw);
      width: calc(17px / vars.$big * 100vw);
      height: calc(17px / vars.$big * 100vw);
    }
    @media screen and (max-width: vars.$medium) {
      right: calc(-24px / vars.$medium * 100vw);
      width: calc(12px / vars.$medium * 100vw);
      height: calc(12px / vars.$medium * 100vw);
    }
    @media screen and (max-width: vars.$small) {
      right: calc(-21px / vars.$small * vars.$smallCoef * 100vw);
      width: calc(12px / vars.$small * vars.$smallCoef * 100vw);
      height: calc(12px / vars.$small * vars.$smallCoef * 100vw);
    }
  }
  @media screen and (max-width: vars.$big) {
    margin-right: calc(34px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    margin-right: calc(24px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    margin-right: calc(21px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.expand-btn {
  grid-column: 4 / 13;
  margin-top: 20px;
  @media screen and (max-width: vars.$big) {
    margin-top: calc(20px / vars.$big * 100vw);
  }

  @media screen and (max-width: vars.$medium) {
    grid-column: 2 / 5;
    margin-top: calc(20px / vars.$medium * 100vw);
  }

  @media screen and (max-width: vars.$small) {
    grid-column: -1 / 1;
    margin-top: calc(10px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.map-circle {
  transition: all 0.5s ease;
}

.map-country-name {
  animation: name-appear 0.5s ease;
}

@keyframes name-appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
