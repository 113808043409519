@use "../../utils/variables" as vars;
@import "../../styles/fonts.scss";

.wrapper {
  position: relative;
  background: vars.$blue;
  display: flex;
  justify-content: center;
  height: 700px;
  overflow: hidden;
  @media screen and (max-width: vars.$big) {
    height: calc(700px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    height: calc(530px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    height: calc(460px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.image {
  z-index: 1;
  position: absolute;
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  @media screen and (max-width: vars.$small) {
    object-position: calc(-300px / vars.$small * vars.$smallCoef * 100vw) 0;
  }
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: vars.$big;
  z-index: 2;
  row-gap: 50px;
  align-items: start;
  padding: 136px 100px;
  padding-bottom: 117px;
  @media screen and (max-width: vars.$big) {
    padding: calc(136px / vars.$big * 100vw) calc(100px / vars.$big * 100vw);
    padding-bottom: calc(117px / vars.$big * 100vw);
    row-gap: calc(50px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    padding: calc(80px / vars.$medium * 100vw) calc(40px / vars.$medium * 100vw);
    padding-bottom: calc(80px / vars.$medium * 100vw);
    row-gap: calc(40px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    padding: calc(40px / vars.$small * vars.$smallCoef * 100vw)
      calc(20px / vars.$small * vars.$smallCoef * 100vw);
    padding-bottom: calc(187px / vars.$small * vars.$smallCoef * 100vw);
    row-gap: calc(40px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.title {
  position: relative;
  grid-column: 1 / 13;
  z-index: 2;
  text-transform: uppercase;
  max-width: 829px;
  color: vars.$blue2;
  @extend .H1;
  @media screen and (max-width: vars.$big) {
    max-width: calc(829px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    max-width: calc(944px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    max-width: calc(350px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.btn {
  position: relative;
  z-index: 2;
  grid-column: 1 / 13;
}
