@use "../../../utils/variables" as vars;
@import "../../../styles/fonts.scss";

.container {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  transition: all 0.5s ease;
  @extend .H4;
  &:hover {
    color: vars.$blue;
    gap: 32px;
  }
  @media screen and (max-width: vars.$big) {
    gap: calc(16px / vars.$big * 100vw);
    &:hover {
      gap: calc(32px / vars.$big * 100vw);
    }
  }
  @media screen and (max-width: vars.$medium) {
    gap: calc(14px / vars.$medium * 100vw);
    &:hover {
      gap: calc(28px / vars.$medium * 100vw);
    }
  }
  @media screen and (max-width: vars.$small) {
    gap: calc(10px / vars.$small * vars.$smallCoef * 100vw);
    &:hover {
      gap: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    }
  }
}

.arrow {
  width: 8px;
  height: 18px;
  margin-top: 3px;
  @media screen and (max-width: vars.$big) {
    width: calc(8px / vars.$big * 100vw);
    height: calc(18px / vars.$big * 100vw);
    margin-top: calc(3px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    width: calc(8px / vars.$medium * 100vw);
    height: calc(18px / vars.$medium * 100vw);
    margin-top: calc(3px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    width: calc(8px / vars.$small * vars.$smallCoef * 100vw);
    height: calc(18px / vars.$small * vars.$smallCoef * 100vw);
    margin-top: calc(2px / vars.$small * vars.$smallCoef * 100vw);
  }
}
