@use "../../../utils/variables" as vars;
@import "../../../styles/fonts.scss";

.container {
  text-transform: uppercase;
  display: flex;
  gap: 16px;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    color: vars.$blue;
  }
  @extend .B2;
  @media screen and (max-width: vars.$big) {
    gap: calc(16px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    gap: calc(12px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    gap: calc(12px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.arrow {
  width: 18px;
  height: 8px;
  @media screen and (max-width: vars.$big) {
    width: calc(18px / vars.$big * 100vw);
    height: calc(8px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    width: calc(18px / vars.$medium * 100vw);
    height: calc(8px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    width: calc(18px / vars.$small * vars.$smallCoef * 100vw);
    height: calc(8px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.opened {
  .arrow {
    transform: rotate(180deg);
  }
}
