@use "../../utils/variables" as vars;

.wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  background: vars.$black;
  padding: 0 100px;
  padding-top: 233px;
  padding-bottom: 22px + vars.$btn_hover_zone;
  @media screen and (max-width: vars.$big) {
    padding: 0 calc(100px / vars.$big * 100vw);
    padding-top: calc(233px / vars.$big * 100vw);
    padding-bottom: calc(22px / vars.$big * 100vw + vars.$btn_hover_zone);
  }
  @media screen and (max-width: vars.$medium) {
    padding: 0 calc(40px / vars.$medium * 100vw);
    padding-top: calc(91px / vars.$medium * 100vw);
    padding-bottom: calc(11px / vars.$medium * 100vw + vars.$btn_hover_zone);
  }
  @media screen and (max-width: vars.$small) {
    padding: 0 calc(20px / vars.$small * vars.$smallCoef * 100vw);
    padding-top: calc(193px / vars.$small * vars.$smallCoef * 100vw);
    padding-bottom: calc(54px / vars.$small * vars.$smallCoef * 100vw + 20px);
    margin-top: -100px;
  }
}

.container {
  justify-content: space-between;
  position: relative;
  color: vars.$white;
  width: 100%;
  max-width: vars.$big;
  grid-template-columns: 1fr auto !important;
  @media screen and (max-width: vars.$medium) {
    grid-template-columns: 1fr !important;
  }
}

.title {
  max-width: 1171px;
  @media screen and (max-width: vars.$big) {
    max-width: calc(1171px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    max-width: calc(944px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    max-width: calc(350px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.solutions {
  justify-self: flex-end;
  @media screen and (max-width: vars.$medium) {
    justify-self: center;
  }
  @media screen and (max-width: vars.$small) {
    margin-top: calc(57px / vars.$small * vars.$smallCoef * 100vw) !important;
  }
}
