@use "../../../utils/variables" as vars;
@import "../../../styles/fonts.scss";

.container {
  color: vars.$black;
  display: flex;
  align-items: center;
  gap: 14px;
  cursor: pointer;
  transition: all 0.5s ease;
  text-transform: uppercase;
  @extend .B2;
  &:hover {
    color: vars.$blue;
    gap: 20px;
  }
  @media screen and (max-width: vars.$big) {
    gap: calc(14px / vars.$big * 100vw);
    &:hover {
      gap: calc(20px / vars.$big * 100vw);
    }
  }
  @media screen and (max-width: vars.$medium) {
    gap: calc(10px / vars.$medium * 100vw);
    &:hover {
      gap: calc(16px / vars.$medium * 100vw);
    }
  }
  @media screen and (max-width: vars.$small) {
    gap: calc(10px / vars.$small * vars.$smallCoef * 100vw);
    &:hover {
      gap: calc(16px / vars.$small * vars.$smallCoef * 100vw);
    }
  }
}

.arrow {
  width: 25px;
  height: 26px;
  margin-top: -2px;
  @media screen and (max-width: vars.$big) {
    width: calc(25px / vars.$big * 100vw);
    height: calc(26px / vars.$big * 100vw);
    margin-top: calc(-2px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    width: calc(20px / vars.$medium * 100vw);
    height: calc(20px / vars.$medium * 100vw);
    margin-top: 0;
  }
  @media screen and (max-width: vars.$small) {
    width: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    height: calc(20px / vars.$small * vars.$smallCoef * 100vw);
  }
}
