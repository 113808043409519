@use "../../../utils/variables" as vars;
@import "../../../styles/fonts.scss";

.container {
  overflow: hidden;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  cursor: pointer;
  height: 380px;
  isolation: isolate;
  padding-bottom: 1px;
  &:not(:last-child) {
    margin-bottom: 80px;
  }
  &:hover {
    .img {
      transform: scale(1.2);
    }
    .btn {
      color: vars.$blue;
      gap: 20px;
    }
  }
  @media screen and (max-width: vars.$big) {
    border-top-right-radius: calc(30px / vars.$big * 100vw);
    border-bottom-right-radius: calc(30px / vars.$big * 100vw);
    height: calc(380px / vars.$big * 100vw);
    &:not(:last-child) {
      margin-bottom: calc(80px / vars.$big * 100vw);
    }
    &:hover {
      .btn {
        gap: calc(20px / vars.$big * 100vw);
      }
    }
  }
  @media screen and (max-width: vars.$medium) {
    border-top-right-radius: calc(20px / vars.$medium * 100vw);
    border-bottom-right-radius: calc(20px / vars.$medium * 100vw);
    height: calc(290px / vars.$medium * 100vw);
    &:not(:last-child) {
      margin-bottom: calc(40px / vars.$medium * 100vw);
    }
    &:hover {
      .btn {
        gap: calc(20px / vars.$medium * 100vw);
      }
    }
  }
  @media screen and (max-width: vars.$small) {
    height: auto;
    border-top-right-radius: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    border-top-left-radius: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    border-bottom-right-radius: 0;
    &:not(:last-child) {
      margin-bottom: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    }
    &:hover {
      .btn {
        gap: calc(20px / vars.$small * vars.$smallCoef * 100vw);
      }
    }
  }
}

.info {
  width: 100%;
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  grid-template-rows: auto 1fr;
  row-gap: 50px;
  align-items: start;
  border: 1px solid vars.$black;
  border-radius: 30px;
  @media screen and (max-width: vars.$big) {
    row-gap: calc(50px / vars.$big * 100vw);
    border: inset calc(1px / vars.$big * 100vw) solid vars.$black;
    border-radius: calc(30px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    row-gap: 0;
    border: inset calc(1px / vars.$medium * 100vw) solid vars.$black;
    border-radius: calc(30px / vars.$medium * 100vw);
    grid-template-rows: auto auto 1fr;
  }
  @media screen and (max-width: vars.$small) {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
    border-radius: 0;
    border-bottom-right-radius: calc(
      20px / vars.$small * vars.$smallCoef * 100vw
    );
    border-bottom-left-radius: calc(
      20px / vars.$small * vars.$smallCoef * 100vw
    );
    border: inset 1px solid vars.$black;
    margin-top: -1px;
  }
}

.title {
  grid-column: 1 / 4;
  padding-top: 60px;
  padding-left: 60px;
  text-transform: uppercase;
  max-width: 341px;
  @extend .H3;
  @media screen and (max-width: vars.$big) {
    max-width: calc(341px / vars.$big * 100vw);
    padding-top: calc(60px / vars.$big * 100vw);
    padding-left: calc(60px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    max-width: none;
    padding-top: calc(40px / vars.$medium * 100vw);
    padding-left: calc(40px / vars.$medium * 100vw);
    grid-column: 1 / 3;
  }
  @media screen and (max-width: vars.$small) {
    padding-top: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    padding-left: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    padding-right: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    grid-column: 1 / 3;
  }
}

.desc {
  grid-column: 4 / 8;
  padding-top: 60px;
  max-width: 424px;
  @extend .T1;
  @media screen and (max-width: vars.$big) {
    max-width: calc(424px / vars.$big * 100vw);
    padding-top: calc(60px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    max-width: none;
    padding-top: calc(20px / vars.$medium * 100vw);
    padding-bottom: calc(40px / vars.$medium * 100vw);
    padding-left: calc(40px / vars.$medium * 100vw);
    grid-column: 1 / 3;
  }
  @media screen and (max-width: vars.$small) {
    padding-top: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    padding-bottom: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    padding-left: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    padding-right: calc(20px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.btn {
  grid-column: 4 / 8;
  @media screen and (max-width: vars.$medium) {
    grid-column: 1 / 3;
    padding-left: calc(40px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    grid-column: 1 / 3;
    padding-left: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    padding-bottom: calc(30px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.img-container {
  width: 100%;
  overflow: hidden;
  grid-column: 9 / 13;
  grid-row: 1 / 2;
  max-width: 586px;
  justify-self: end;
  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transform: scale(1.01);
    transition: all 0.5s ease;
  }
  @media screen and (max-width: vars.$big) {
    max-width: calc(586px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    grid-column: 3 / 5;
    max-width: calc(452px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    grid-column: 1 / 3;
    max-width: none;
    justify-self: start;
  }
}
