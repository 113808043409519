@use "../utils/variables" as vars;

.container {
  position: relative;
}

.content {
  background: vars.$white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.section_img {
  height: 76rem;
  overflow: hidden;
  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.video_header {
  width: 100%;
  height: 764px;
  transform: scale(1.01);
  object-fit: cover;
  object-position: center center;
  background-color: vars.$blue2;
  @media screen and (max-width: vars.$big) {
    height: calc(764px / vars.$big * 100vw);
  }

  @media screen and (max-width: vars.$medium) {
    height: calc(768px / vars.$medium * 100vw);
  }

  @media screen and (max-width: vars.$small) {
    height: calc(844px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.products,
.solutions {
  &__header {
    margin-top: 160px;
    margin-bottom: 120px;
  }
  &__btn {
    align-self: center;
  }
  &__card {
  }
  @media screen and (max-width: vars.$big) {
    &__header {
      margin-top: calc(160px / vars.$big * 100vw);
      margin-bottom: calc(120px / vars.$big * 100vw);
    }
  }

  @media screen and (max-width: vars.$medium) {
    &__header {
      margin-top: calc(120px / vars.$medium * 100vw);
      margin-bottom: calc(80px / vars.$medium * 100vw);
    }
  }

  @media screen and (max-width: vars.$small) {
    &__header {
      margin-top: calc(60px / vars.$small * vars.$smallCoef * 100vw);
      margin-bottom: calc(60px / vars.$small * vars.$smallCoef * 100vw);
    }
  }
}
.products {
  background: vars.$blue2;
  &__btn {
    margin-top: 40px;
    margin-bottom: 120px;
  }

  @media screen and (max-width: vars.$big) {
    &__btn {
      margin-top: calc(40px / vars.$big * 100vw);
      margin-bottom: calc(120px / vars.$big * 100vw);
    }
  }

  @media screen and (max-width: vars.$medium) {
    &__btn {
      margin-top: calc(40px / vars.$medium * 100vw);
      margin-bottom: calc(80px / vars.$medium * 100vw);
    }
  }

  @media screen and (max-width: vars.$small) {
    &__btn {
      margin-top: calc(50px / vars.$small * vars.$smallCoef * 100vw);
      margin-bottom: calc(70px / vars.$small * vars.$smallCoef * 100vw);
    }
  }
}

.solutions {
  &__btn {
    margin-bottom: -40px;
    margin-top: 40px;
    @media screen and (max-width: vars.$big) {
      margin-top: calc(40px / vars.$big * 100vw);
      margin-bottom: calc(0px - vars.$btn_hover_zone / vars.$big * 100vw);
    }
    @media screen and (max-width: vars.$medium) {
      margin-top: calc(40px / vars.$medium * 100vw);
      margin-bottom: calc(0px - vars.$btn_hover_zone / vars.$medium * 100vw);
    }

    @media screen and (max-width: vars.$small) {
      margin-top: calc(50px / vars.$small * vars.$smallCoef * 100vw);
      margin-bottom: 0px;
    }
  }
}

.discuss {
  margin-top: 160px;
  margin-bottom: 160px;
  @media screen and (max-width: vars.$big) {
    margin-top: calc(160px / vars.$big * 100vw);
    margin-bottom: calc(160px / vars.$big * 100vw);
  }

  @media screen and (max-width: vars.$medium) {
    margin-top: calc(120px / vars.$medium * 100vw);
    margin-bottom: calc(120px / vars.$medium * 100vw);
  }

  @media screen and (max-width: vars.$small) {
    margin-top: calc(80px / vars.$small * vars.$smallCoef * 100vw);
    margin-bottom: calc(80px / vars.$small * vars.$smallCoef * 100vw);
  }
}
