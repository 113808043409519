@use "../../../utils/variables" as vars;
@import "../../../styles/fonts.scss";

.container {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 274px + calc(2 * vars.$btn_hover_zone);
  height: 274px + calc(2 * vars.$btn_hover_zone);
  color: vars.$white;
  border-radius: 50%;
  user-select: none;
  text-transform: uppercase;
  &:hover {
    &::after {
      width: 100%;
      height: 100%;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 274px;
    height: 274px;
    border-radius: 50%;
    background: vars.$blue;
    transition: all 0.5s ease;
  }
  // margin: 80px 0;
  @media screen and (max-width: vars.$big) {
    width: calc(274px / vars.$big * 100vw + 2 * vars.$btn_hover_zone);
    height: calc(274px / vars.$big * 100vw + 2 * vars.$btn_hover_zone);
    &::after {
      width: calc(274px / vars.$big * 100vw);
      height: calc(274px / vars.$big * 100vw);
    }
  }
  @media screen and (max-width: vars.$medium) {
    width: calc(180px / vars.$medium * 100vw + 2 * vars.$btn_hover_zone);
    height: calc(180px / vars.$medium * 100vw + 2 * vars.$btn_hover_zone);
    &::after {
      width: calc(180px / vars.$medium * 100vw);
      height: calc(180px / vars.$medium * 100vw);
    }
  }
  @media screen and (max-width: vars.$small) {
    width: calc(180px / vars.$small * vars.$smallCoef * 100vw + 2 * 10px);
    height: calc(180px / vars.$small * vars.$smallCoef * 100vw + 2 * 10px);
    &::after {
      width: calc(180px / vars.$small * vars.$smallCoef * 100vw);
      height: calc(180px / vars.$small * vars.$smallCoef * 100vw);
    }
  }
}

.title {
  position: relative;
  z-index: 3;
  @extend .B1;
}
