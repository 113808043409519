@use "../../utils/variables" as vars;
@import "../../styles/fonts.scss";

.container {
  color: vars.$black;
  align-items: start;
}

.btn {
  grid-column: 1 / 3;
  @media screen and (max-width: vars.$medium) {
    grid-column: 1 / -1;
    margin-bottom: calc(40px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    margin-bottom: calc(22px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.desc {
  grid-column: 4 / 13;
  text-transform: uppercase;
  @extend .H2;
  @media screen and (max-width: vars.$medium) {
    grid-column: 1 / -1;
  }
}
